<template>
  <div class="cointer">
    <div class="sititle">
      <div class="siti">{{ form.name }}</div>
      <div class="siback" @click="goBack()">
        <div
          v-if="(form.status == 15 || form.status == 17) && end_time"
          style="margin-right: 15px; line-height: 33px"
        >
          截止日期：<span style="color: red"
            >{{ end_time | formatDate }}前</span
          >
        </div>
        <!-- <div class="sibimg">
          <img
            alt=""
            src="https://api.gbservice.cn/88356f0bc29e9e2e3ca34fcbf8d6c98f.png"
          />
        </div> -->
        <div class="sicback"><i class="el-icon-right"></i>返回</div>
      </div>
    </div>
    <div class="stepcont">
      <el-steps :active="step" :space="200" finish-status="success">
        <el-step title="形式审核"></el-step>
        <el-step title="技术审核"></el-step>
        <el-step title="公示公告"></el-step>
        <el-step title="标识发放"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>
    <div class="sicontent">
      <div class="sicleft">
        <router-link
          :to="{ path: 'ipFormtedail?project_id=' + project_id }"
          class="detailPush"
        >
          <div class="siclkuai">
            <div class="siclleft">申报信息</div>
            <div class="siczhtai">
              <el-tag v-if="shenbao_check.pass == 1" type="success"
                >达标
              </el-tag>
              <el-tag v-else-if="shenbao_check.pass == 2" type="danger"
                >未达标
              </el-tag>
              <el-tag v-else-if="shenbao_check.pass == 3" type="warning"
                >待定
              </el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ path: 'ipTotaltable?project_id=' + project_id }"
          class="detailPush"
        >
          <div class="siclkuai">
            <div class="siclleft">能效测评汇总表</div>
            <div class="siczhtai">
              <el-tag v-if="nengxiao_check.pass == 1" type="success"
                >达标
              </el-tag>
              <el-tag v-else-if="nengxiao_check.pass == 2" type="danger"
                >未达标
              </el-tag>
              <el-tag v-else-if="nengxiao_check.pass == 3" type="info"
                >待定
              </el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <div class="siclkuai">
          <div class="siclleft">
            <div
              class="siclname"
              style="color: #54acff; cursor: pointer"
              @click="gotoTable"
            >
              能效测评报告
            </div>
            <div class="dowlend">
              <span
                @click="preViewFile"
                style="margin-right: 20px; cursor: pointer"
                >预览</span
              >

              <span
                :style="
                  (form.status != 15 && form.status != 17
                    ? 'color:#999999'
                    : '') + 'cursor: pointer;'
                "
                @click="nengxiaoShow = true"
                >上传</span
              >
            </div>
          </div>
          <div
            v-if="form.status < 5 || form.status == 15 || form.status == 16"
            class="siczhtai"
          >
            <el-tag v-if="baogao_check.pass == 1" type="success">达标</el-tag>
            <el-tag v-else-if="baogao_check.pass == 2" type="danger"
              >未达标
            </el-tag>
            <el-tag v-else-if="baogao_check.pass == 3" type="warning"
              >待定</el-tag
            >
            <el-tag v-else type="info">未审查</el-tag>
          </div>
          <div v-else class="siczhtai">
            <el-tag v-if="option1.pass == 1" type="success">达标</el-tag>
            <el-tag v-else-if="option1.pass == 2" type="danger">未达标</el-tag>
            <el-tag v-else-if="option1.pass == 3" type="warning">待定</el-tag>
            <el-tag v-else type="info">未审查</el-tag>
          </div>
        </div>
        <router-link
          :to="{ path: 'ipTable?project_id=' + project_id }"
          class="detailPush"
        >
          <div class="siclkuai">
            <div class="siclleft">能效标识申报指标情况表</div>
            <div class="siczhtai">
              <el-tag v-if="zhibiao_check.pass == 1" type="success"
                >达标
              </el-tag>
              <el-tag v-else-if="zhibiao_check.pass == 2" type="danger"
                >未达标
              </el-tag>
              <el-tag v-else-if="zhibiao_check.pass == 0" type="info"
                >待定
              </el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <div>
          <div class="siclkuai">
            <div class="siclleft">
              <div class="siclname">附件</div>
              <div class="dowlend">
                <el-upload
                  :disabled="form.status != 15 && form.status != 17"
                  :file-list="fujian_file"
                  :on-success="fujia_upload_success"
                  :show-file-list="true"
                  action="https://api.gbservice.cn/api/public/bupload/"
                  class="upload-demo"
                >
                  <span
                    :style="
                      form.status != 15 && form.status != 17
                        ? 'color:#999999'
                        : ''
                    "
                    >上传</span
                  >
                </el-upload>
                <span
                  @click.stop="downloadFile()"
                  style="cursor: pointer; margin-left: 25px"
                  >下载</span
                >
              </div>
            </div>
            <div
              v-if="form.status < 5 || form.status == 15 || form.status == 16"
              class="siczhtai"
            >
              <el-tag
                v-if="fujian_check.pass == 1"
                style="cursor: pointer"
                type="success"
                >达标
              </el-tag>
              <el-tag
                v-else-if="fujian_check.pass == 2"
                style="cursor: pointer"
                type="danger"
                >未达标
              </el-tag>
              <el-tag
                v-else-if="fujian_check.pass == 3"
                style="cursor: pointer"
                type="info"
                >待定
              </el-tag>
              <el-tag v-else style="cursor: pointer" type="info">未审查</el-tag>
              <!--             <el-tag  type="info" style="cursor: pointer;">未审查</el-tag>-->
            </div>
            <div v-else class="siczhtai">
              <el-tag
                v-if="jishu_fujian_check.pass == 1"
                style="cursor: pointer"
                type="success"
                >达标
              </el-tag>
              <el-tag
                v-else-if="jishu_fujian_check.pass == 2"
                style="cursor: pointer"
                type="danger"
                >未达标
              </el-tag>
              <el-tag
                v-else-if="jishu_fujian_check.pass == 3"
                style="cursor: pointer"
                type="info"
                >待定
              </el-tag>
              <el-tag v-else style="cursor: pointer" type="info">未审查</el-tag>
              <!--             <el-tag  type="info" style="cursor: pointer;">未审查</el-tag>-->
            </div>
          </div>
        </div>
      </div>
      <div class="siccenter">
        <div class="sicctitle">形式审查意见汇总</div>
        <div class="sicctxt">
          <p v-if="shenbao_check.idea">申报信息: {{ shenbao_check.idea }}</p>
          <p v-if="nengxiao_check.idea">
            能效测评汇总表: {{ nengxiao_check.idea }}
          </p>
          <p v-if="baogao_check.idea">能效测评报告: {{ baogao_check.idea }}</p>
          <p v-if="zhibiao_check.idea">
            能效标识申报指标情况表: {{ zhibiao_check.idea }}
          </p>
          <p v-if="fujian_check.idea">附件: {{ fujian_check.idea }}</p>
        </div>
        <div class="sicctitle">技术审查意见汇总</div>
        <div class="sicctxt">
          <table border="1" cellspacing="0" width="100%">
            <tbody>
              <tr>
                <th scope="col">&nbsp;项目名称</th>
                <th colspan="3" scope="col">{{ form.name }}</th>
              </tr>
              <tr>
                <th scope="row">审核指标&nbsp;</th>
                <th colspan="2">&nbsp;申报情况</th>
                <th>&nbsp;评审意见</th>
              </tr>
              <tr>
                <th scope="row">相对节能率&nbsp;</th>
                <td colspan="2">{{ formtable.evaluationWay_heatEnergy }}</td>
                <td>{{ result.export_heatEnergy }}</td>
              </tr>
              <tr>
                <th scope="row">节能率&nbsp;</th>
                <td colspan="2">{{ formtable.relateEnergy }}</td>
                <td>{{ result.export_relateEnergy }}</td>
              </tr>
              <tr>
                <th scope="row">&nbsp;基础项</th>
                <td colspan="2">
                  经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                  3964-2020标准要求，建筑节能率为{{
                    formtable.buildEnergyRatio
                  }}，测评合格。
                </td>
                <td>{{ option1.idea }}</td>
              </tr>
              <tr>
                <th scope="row">规定项&nbsp;</th>
                <td colspan="2">
                  经测评，该项目规定项{{
                    formtable.strip
                  }}条参评，均满足《民用建筑能效测评标识标准》DB32/T
                  3964-2020规定要求；
                </td>
                <td>{{ option2.idea }}</td>
              </tr>
              <tr>
                <th scope="row">&nbsp;选择项</th>
                <td colspan="2">
                  经测评，该项目选择项{{ formtable.strip }}条参评，加{{
                    formtable.grades
                  }}分；
                </td>
                <td>{{ option3.idea }}</td>
              </tr>
              <tr>
                <th scope="row">建筑热工性能&nbsp;</th>
                <td colspan="2">
                  屋面保温:{{ formtable.keepwarm }};面传热系数[W/(m2·K)]:{{
                    formtable.heattransfer
                  }};外墙保温:{{ formtable.roofWarm }};外墙传热系数[W/(m2·K)]:{{
                    formtable.outHeat
                  }};外窗型材:{{
                    formtable.exterProfile
                  }}
                  外窗传热系数[W/(m2·K)]：东{{ formtable.outEaast }}、南{{
                    formtable.outSouth
                  }}、西{{ formtable.outWest }}、北{{
                    formtable.outNorth
                  }};遮阳系数SC：东{{ formtable.scEaast }}、南{{
                    formtable.scSouth
                  }}、 西{{ formtable.scWest }}、北{{ formtable.scNorth }}。
                </td>
                <td>{{ result.export_buildhot }}</td>
              </tr>
              <tr>
                <th scope="row">单位建筑面积全年能耗量</th>
                <td colspan="2">{{ formtable.buildEnergy }}</td>
                <td>{{ result.export_buildEnergy }}</td>
              </tr>
              <tr>
                <th scope="row">&nbsp;专家评审意见 *</th>
                <td colspan="3">{{ result.export_totalopinion }}</td>
              </tr>
              <tr>
                <th rowspan="3" scope="row">&nbsp;结 论 *</th>
                <td colspan="2">&nbsp;相对节能率(%)：</td>
                <td>{{ result.export_evaluationWay }}</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;单位建筑面积能量（kW·h/m2）：</td>
                <td>{{ result.export_areacoldenergy }}</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;星级（无、一星、二星、三星）：</td>
                <td>
                  <i
                    v-for="item in result.after_stars"
                    :key="item"
                    class="el-icon-star-on"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="yjdow" @click="downloadTable">下载意见表</div>
      </div>
      <div class="sicright">
        <div class="sicctitle">历史记录</div>
        <div class="sicrtxt">
          <el-timeline :reverse="reverse">
            <el-timeline-item
              v-for="(activity, index) in log"
              :key="index"
              :timestamp="activity.created_at"
            >
              {{ activity.type }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div class="scibtn">
      <el-button
        type="primary"
        :disabled="!(form.status == 15 || form.status == 17)"
        @click="saveFiles(1)"
        >保存
      </el-button>
      <el-button
        :disabled="!(form.status == 15 || form.status == 17)"
        type="primary"
        @click="saveFiles(2)"
        >提交
      </el-button>
      <!-- <el-button type="primary" @click="pass_pro">通过</el-button> -->
    </div>

    <!--    修改能效测评报告-->
    <el-dialog
      :visible.sync="nengxiaoShow"
      title="修改能效测评报告"
      width="30%"
    >
      <div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;
          "
        >
          <div style="width: 100px">报告编号：</div>
          <el-input v-model="form.number" placeholder="请输入内容"></el-input>
        </div>
        <div style="margin-top: 20px">
          <el-upload
            class="upload-demo"
            action="https://api.gbservice.cn/api/public/bupload/"
            :file-list="nengxiao_file"
            accept=".docx,.pdf"
            :on-success="nengxiao_upload_success"
            
          >
            <el-button size="small" type="primary">上传报告</el-button>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nengxiaoShow = false">取 消</el-button>
        <el-button type="primary" @click="nengxiaoShow = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!--    预览文件-->
    <el-dialog :visible.sync="previewFileShow">
      <div class="fdltxt">
        <div>报告编号：{{ form.number }}</div>
        <iframe
          v-if="fileType == 1"
          style="width: 100%; height: 76vh"
          :src="showUrl"
        ></iframe>
        <div v-else-if="fileType == 2">
          <div
            ref="docx_dom"
            style="width: 100%; height: 76vh; overflow-y: scroll"
          ></div>
        </div>
        <div v-else></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  checkBack,
  checkPass,
  checkSave,
  projectTableInfo,
  storeProject,
} from "../../../../api/project";
import axios from "axios";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
let docx = require("docx-preview");

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      end_time: "",
      haveUpload: false,
      showUrl: "",
      fileType: "",
      previewFileShow: false,
      nengxiaoShow: false,
      fujian_file: [],
      nengxiao_file: [],

      reverse: true,
      idea: "",
      activities: [
        {
          content: "提交",
          timestamp: "2018-04-15",
        },
        {
          content: "打回修改",
          timestamp: "2018-04-13",
        },
        {
          content: "提交",
          timestamp: "2018-04-11",
        },
      ],
      project_id: "",
      form: {},
      checks: [],
      formtable: {},
      result: {},

      shenbao_check: {},
      nengxiao_check: {},
      baogao_check: {},
      zhibiao_check: {},
      fujian_check: {},
      zong_check: {},
      option1: {},
      option2: {},
      option3: {},
      jishu_fujian_check: {},

      pass: "",
      yijian: "",
      log: [],

      step: 0,
      word_data: {
        evaluationWay_heatEnergy: "无",
        export_heatEnergy: "无评审意见",
        relateEnergy: "无",
        export_relateEnergy: "无评审意见",
        buildEnergyRatio: "无",
        idea1: "无评审意见",
        idea2: "无评审意见",
        idea3: "无评审意见",
        strip: 0,
        grades: 0,
        keepwarm: "无",
        heattransfer: "无",
        roofWarm: "无",
        outHeat: "无",
        exterProfile: "",
        outEaast: "无",
        outSouth: "无",
        outWest: "无",
        outNorth: "无",
        scEaast: "无",
        scSouth: "无",
        scWest: "无",
        scNorth: "无",
        export_buildhot: "无评审意见",
        buildEnergy: "无",
        export_buildEnergy: "无评审意见",
        export_totalopinion: "无评审意见",
        export_evaluationWay: "无评审意见",
        export_areacoldenergy: "无评审意见",
        after_stars: "无评审意见",
        zname: "无",
        now: "无",
      },
    };
  },
  mounted() {
    this.project_id = this.$route.query.id;
    this.getInfo();
  },
  methods: {
    //去除对象中值为null的属性
    removePropertyOfNull(obj) {
      Object.keys(obj).forEach((item) => {
        if (!obj[item]) delete obj[item];
      });
      return obj;
    },
    // 下载评审表
    downloadTable() {
      if (!this.word_data) this.word_data = {};
      Object.assign(this.word_data, this.removePropertyOfNull(this.form));
      Object.assign(this.word_data, this.removePropertyOfNull(this.formtable));
      Object.assign(this.word_data, this.removePropertyOfNull(this.result));
      if (this.option1.idea) {
        this.word_data.idea1 = this.option1.idea;
      }
      if (this.option2.idea) {
        this.word_data.idea2 = this.option2.idea;
      }
      if (this.option3.idea) {
        this.word_data.idea3 = this.option3.idea;
      }
      // let now = new Date();
      // this.word_data.now =
      //   now.getFullYear() +
      //   "-" +
      //   (now.getMonth() + 1) +
      //   "-" +
      //   now.getDate() +
      //   " " +
      //   now.getHours() +
      //   ":" +
      //   now.getMinutes() +
      //   ":" +
      //   now.getSeconds();

      console.log(this.word_data);

      let _this = this;
      // 判断有无附加商品来选择word模版
      // 读取并获得模板文件的二进制内容
      JSZipUtils.getBinaryContent("mydowload.docx", function (error, content) {
        console.log("-----", content);
        // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
        // 抛出异常
        if (error) {
          throw error;
        }
        // 创建一个JSZip实例，内容为模板的内容
        let zip = new JSZip(content);
        console.log("+++++", zip);
        // 创建并加载docxtemplater实例对象
        let doc = new Docxtemplater();
        console.log("/////", doc);
        doc.loadZip(zip);
        console.log("=====", doc);
        // 设置模板变量的值
        doc.setData({
          // 导出价格表全部信息
          ..._this.word_data,
        });
        try {
          // 用模板变量的值替换所有模板变量
          doc.render();
        } catch (error) {
          // 抛出异常
          let e = {
            message: error.message,
            name: error.name,
            stack: error.stack,
            properties: error.properties,
          };
          console.log(JSON.stringify({ error: e }));
          throw error;
        }
        // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
        let out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // 将目标文件对象保存为目标类型的文件，并命名
        saveAs(out, _this.word_data.name + "的民用建筑能效标识评审表.docx");
      });
    },
    downloadFile() {
      let files = this.form.files;
      for (let i = 0; i < files.length; i++) {
        if (files[i].type == 4) {
          let url = files[i].url;
          const a = document.createElement("a");
          a.href = url;
          a.target = "_blank";
          a.download = files[i].fileName; // 下载后文件名
          a.style.display = "none";
          document.body.appendChild(a);
          a.click(); // 点击下载
          document.body.removeChild(a); // 下载完成移除元素
        }
      }
    },

    //预览文件
    preViewFile() {
      if (this.form.files) {
        console.log("预览的文件", this.form.files);
        let o_files = this.form.files
          .map((item) => {
            if (item.type == 3) {
              return item;
            }
          })
          .filter((d) => d);
        console.log("预览的文件", o_files);
        if (o_files && o_files.length > 0) {
          this.previewFileShow = true;
          let file_type = o_files[o_files.length - 1].url.split(".");
          if (file_type[file_type.length - 1] == "pdf") {
            this.showUrl = o_files[o_files.length - 1].url;
            this.fileType = 1;
          } else {
            this.fileType = 2;
            // let file_name_arr = o_files[o_files.length - 1].url.split("/");
            // let file_name = file_name_arr[file_name_arr.length - 1];
            this.$nextTick(function () {
              axios({
                method: "get",
                responseType: "blob", // 设置响应文件格式
                url: o_files[o_files.length - 1].url,
              }).then(({ data }) => {
                docx.renderAsync(data, this.$refs.docx_dom); // 获取到biob文件流，进行渲染到页面预览
              });
            });
          }
        } else {
          this.$message.warning("暂无文件可以预览");
        }
      } else {
        if (!this.haveUpload) {
          this.$message.warning("请先上传新的报告");
          return false;
        } else {
          this.$message.warning("暂无文件可以预览");
        }
      }
    },

    gotoTable() {
      this.$router.push({
        path: "/user_mark/ipreporttable?project_id=" + this.project_id,
      });
    },
    //保存上传的文件信息 type==1只保存 type==2保存并提交
    saveFiles(type) {
      let resub = 1;
      if (this.form.status == 15) {
        resub = 1;
      } else if (this.form.status == 17) {
        resub = 2;
      }

      let params = {
        id: this.project_id,
        token: this.$store.state.token,
        files: this.form.files,
        number: this.form.number,
      };
      //标识是保存还是提交
      if (type == 2) {
        params.resub = resub;
      }
      if (params.files) {
        for (let i = 0; i < params.files.length; i++) {
          if (params.files[i].file) {
            params.files[i].name = params.files[i].file;
          }
        }
      }
      let that = this;
      storeProject(params).then((res) => {
        console.log(res);
        if (type == 2) {
          that.$message.success("提交成功！");
          that.$router.push({ path: "identpend" });
        } else {
          that.$message.success("保存成功！");
        }
      });
    },

    //上传附件成功回调
    fujia_upload_success(response, file, fileList) {
      if (this.form.files) {
        let o_files = this.form.files
          .map((item) => {
            if (item.type == 4) {
              return undefined;
            } else {
              return item;
            }
          })
          .filter((d) => d);
        this.form.files = o_files;
      }
      let new_file = fileList[fileList.length - 1].response.data;
      new_file.type = 4;
      this.form.files.push(new_file);
      this.$message.success("附件上传成功");
    },

    //能效测评报告上传成功回调
    nengxiao_upload_success(response, file, fileList) {
      console.log("23232");
      if (this.form.files) {
        let o_files = this.form.files
          .map((item) => {
            if (item.type == 3) {
              return undefined;
            } else {
              return item;
            }
          })
          .filter((d) => d);
        this.form.files = o_files;
      }
      let new_file = fileList[fileList.length - 1].response.data;
      new_file.type = 3;
      this.form.files.push(new_file);
      console.log("上传后的文件：", this.form.files);
      this.nengxiao_file.splice(0);
      this.nengxiao_file.push(new_file);
      this.haveUpload = true;
      this.$message.success("报告上传成功");
    },
    goBack() {
      let path = this.$route.path;
      if (path.indexOf("user_project") != -1) {
        this.$router.push({
          path: "/user_project/itemIdent",
        });
      } else if (path.indexOf("user_mark") != -1) {
        this.$router.push({
          path: "/user_mark/identpend",
        });
      }
    },
    //通过
    pass_pro() {
      if (this.form.batch && this.form.batch != "") {
        let params = {
          idea: this.idea,
          pass: 1,
          project_id: this.project_id,
          type: 6,
          token: this.$store.state.token,
        };
        if (this.shenbao_check.pass != 1) {
          this.$message.warning("需要前面所有步骤达标！");
          return false;
        }
        if (this.nengxiao_check.pass != 1) {
          this.$message.warning("需要前面所有步骤达标！");
          return false;
        }
        if (this.baogao_check.pass != 1) {
          this.$message.warning("需要前面所有步骤达标！");
          return false;
        }
        if (this.zhibiao_check.pass != 1) {
          this.$message.warning("需要前面所有步骤达标！");
          return false;
        }
        if (this.fujian_check.pass != 1) {
          this.$message.warning("需要前面所有步骤达标！");
          return false;
        }

        let that = this;
        checkPass(params).then((res) => {
          console.log(res);
          that.$message.success("成功通过");
          that.$router.push({
            path: "./formalPend",
          });
        });
      } else {
        this.$message.warning("请先建立批次");
      }
    },

    //打回
    back() {
      let params = {
        idea: this.idea,
        pass: 2,
        project_id: this.project_id,
        type: 6,
        token: this.$store.state.token,
      };
      let that = this;
      checkBack(params).then((res) => {
        console.log(res);
        that.$message.success("打回成功");
        that.$router.push({
          path: "./formalPend",
        });
      });
    },

    check() {
      let params = {
        idea: this.idea,
        pass: 3,
        project_id: this.project_id,
        type: 6,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        that.$router.push({
          path: "./formalPend",
        });
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        if (
          that.form.status < 5 ||
          that.form.status == 15 ||
          that.form.status == 16
        ) {
          that.step = 0;
        } else if (
          that.form.status < 9 ||
          that.form.status == 17 ||
          that.form.status == 18
        ) {
          that.step = 1;
        } else if (that.form.status == 14) {
          that.step = 4;
        } else if (that.form.status == 10 || that.form.status == 11) {
          that.step = 2;
        } else {
          that.step = 3;
        }
        // if(that.form.status>8 && that.form.status!=15 && that.form.status!=16 && that.form.status!=17 && that.form.status!=18 && that.form.status!=19){
        //   that.showBtn = false;
        //   this.from = 3;
        //   if (that.form.status<12){
        //     this.step = 2;
        //     if(that.form.status==6&&that.form.status==5 &&that.form.status==4&&that.form.status==8){
        //       this.step=1;
        //    }
        //   }
        //    if(that.form.status==14){
        //       this.step=4;
        //    }
        //   else {

        //     this.step=3;
        //   }

        // }
        that.formtable = that.form.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        that.result = that.form.report;
        if (!that.result) {
          that.result = {};
        }
        that.checks = res.data.checks;
        that.log = res.data.log;

        let yijian_total = "";

        for (let i = 0; i < that.checks.length; i++) {
          // if (that.checks[i].idea && that.checks[i].idea!='' ){
          //   yijian_total += that.checks[i].idea+'\n';
          // }
          if (that.checks[i].type == 1) {
            that.shenbao_check = that.checks[i];
          } else if (that.checks[i].type == 2) {
            that.nengxiao_check = that.checks[i];
          } else if (that.checks[i].type == 3) {
            that.baogao_check = that.checks[i];
          } else if (that.checks[i].type == 4) {
            that.zhibiao_check = that.checks[i];
          } else if (that.checks[i].type == 5) {
            that.fujian_check = that.checks[i];
          } else if (that.checks[i].type == 6) {
            that.zong_check = that.checks[i];
          } else if (that.checks[i].type == 7) {
            that.option1 = that.checks[i];
            that.worad_data_now = that.checks[i].created_at;
          } else if (that.checks[i].type == 8) {
            that.option2 = that.checks[i];
          } else if (that.checks[i].type == 9) {
            that.option3 = that.checks[i];
          } else if (that.checks[i].type == 10) {
            that.jishu_fujian_check = that.checks[i];
          }
        }
        //获取最后一次审核记录，并获取截止时间
        if (that.checks?.length) {
          this.end_time = that.checks[that.checks.length - 1].limitDate;
        }
        that.yijian = yijian_total;
      });
    },
  },
};
</script>
<style scoped>
/deep/ .el-textarea > textarea {
  height: 400px;
}

/deep/ .el-textarea__inner {
  border: 0;
}
</style>
<style lang="scss" scoped>
.yjdow {
  display: flex;
  flex-direction: row-reverse;
  color: #3086fb;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}
.detailPush {
  text-decoration: none;
}

.sititle {
  margin: 47px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .siti {
    margin-left: 310px;
    font-size: 30px;
    font-family: Adobe Heiti Std;
    color: #333333;
  }

  .siback {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    cursor: pointer;

    .sibimg {
      margin-top: 3px;

      img {
        width: 26px;
        height: 26px;
      }
    }

    .sicback {
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      color: #3086fb;
      margin-left: 6px;
    }
  }
}

.stepcont {
  width: 1150px;
  margin: 50px auto;
}

.sicontent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 15px;

  .sicleft {
    .siclkuai {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .siclleft {
        width: 430px;
        height: 80px;
        line-height: 80px;
        background: #ffffff;
        border-radius: 4px;
        border-left: 10px solid #54acff;
        margin-bottom: 17px;
        padding-left: 32px;
        font-size: 24px;
        color: #54acff;
        font-family: Adobe Heiti Std;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        text-decoration: none;

        .siclname {
          text-decoration: none;
          font-size: 24px;
        }

        .dowlend {
          text-align: right;
          margin-right: 15px;
          color: #3086fb;
          font-size: 14px;
          display: flex;
          flex-direction: row;
        }
      }

      .siczhtai {
        margin: 30px 32px;
      }
    }
  }

  .siccenter {
    .sicctitle {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      margin-bottom: 10px;
    }

    .sicctxt {
      width: 660px;
      height: 176px;
      // padding-left: 10px;
      background: #ffffff;
      box-shadow: 0px 3px 8px 1px rgba(204, 204, 204, 0.3);
      border-top: 10px solid #4aa1fe;
      border-radius: 4px;
      overflow-y: scroll;
    }

    .sicctxt::-webkit-scrollbar {
      display: none;
    }
  }

  .sicright {
    .sicctitle {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      margin-bottom: 10px;
    }

    .sicrtxt {
      width: 340px;
      height: 370px;
      overflow-y: scroll;
      background: #ffffff;
      box-shadow: 0px 3px 8px 1px rgba(204, 204, 204, 0.3);
      border-radius: 4;
      padding: 20px 0;
    }

    .sicrtxt::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }
  }
}

.scibtn {
  margin-top: 65px;
  text-align: center;
}
</style>
